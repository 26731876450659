.product-detail-section {
  padding: 100px 0;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding: 80px 0 40px;
  }

  .bg-dotted-triangle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    img {
      max-width: 100%;
    }
  }

  .description {
    width: 100%;
    max-width: 940px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    position: relative;
    z-index: 10;

    p {
      margin-bottom: 30px;
    }
  }
}