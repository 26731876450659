.category-detail-section {
  padding: 140px 0 0px;

  @media only screen and (max-width: 992px) {
    padding: 80px 0 0px;
  }

  .content-row {
    display: flex;
    gap: 60px;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }

    .description {
      flex: 1;

      .description-text {
        font-size: 18px;
        line-height: 1.5;
        color: #3D3E40;
        margin-bottom: 35px;
      }
    }

    .medias {
      flex: 1;
      min-height: 742px;
      background-color: #FFDE59;
      padding: 24px;
      margin-right: 24px;
      position: relative;

      @media only screen and (max-width: 992px) {
        min-height: unset;
        max-width: 420px;
        margin: 30px auto 0;
      }

      &:before, &:after {
        content: '';
        position: absolute;
      }

      &:before {
        width: 88px;
        height: 88px;
        background: url(../../assets/images/rounded-dotted-grid.svg);
        top:-77px;
        right: 48px;
      }

      &:after {
        width: 35px;
        height: 154px;
        background: url(../../assets/images/square-boxes-grid.svg);
        bottom: 48px;
        left: -35px;
      }

      &.media-width-multiple-images {
        min-height: unset;

        @media only screen and (max-width: 992px) {
          max-width: unset;
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          max-width: 420px;
          width: 100%;
        }
      }

      .media-wrapper {
        margin: -48px -48px 0 0;
        overflow: hidden;
        height: calc(100% + 48px);
        position: relative;
        z-index: 10;

        .img-holder {
          width: 100%;
          height: 100%;
          overflow: hidden;

          img {
            width: 100%;
            min-height: 100%;
          }
        }

        &.multiple {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 24px;
          margin: 0 0 0 -48px;
          height: auto;

          @media only screen and (min-width: 768px ) and (max-width: 992px) {
            flex-direction: row;
            margin-left: 0;
            margin-top: -48px;
          }

          .img-holder {
            height: 300px;
          }
        }
      }
      
    }
  }
}