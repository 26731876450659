.form-group {
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .error {
    color: #f00;
    font-size: 12px;
    position: absolute;
  }

  .form-control, input[type="text"], input[type="date"] {
    width: 100%;
    height: 54px;
    border: 1px solid #3D3E40;
    font-size: 14px;
    padding: 0 15px
  }
  input[type="date"]::-webkit-calendar-picker-indicator {opacity: 0;}

  &.disabled {
    label {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .form-control {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.calendar-control {
  position: relative;

  input[type="text"], input[type="date"] {
    cursor: pointer;
    position: relative;
    z-index: 100;
    background: transparent;
  }

  .calendar-icon {
    position: absolute;
    right: 15px;
    top: 17px;

  }
}