.section-heading {
  display: flex;
  gap: 14px;
  margin-bottom: 34px;
  
  .icon-container {
    width: 100px;
    height: 100px;
    background-color: #001750;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 767px) {
      width: 70px;
      height: 70px;

      img {width: 40px;}
    }
  }

  h2 {
    color: #001750;
    font-size: 48px;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    padding-top: 19px;

    @media only screen and (max-width: 992px) {
      font-size: 34px;
      max-width: 500px;
      padding-top: 36px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 30px;
      padding-top: 12px;
    }

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 7px;
      margin-top: 10px;
      background-color: #001750;
    }
  }

  &.center {
    display: block;
    text-align: center;

    h2 {
      &:after {margin: 20px auto 0;}
      
      @media only screen and (max-width: 992px) {
        margin: 18px auto 0;
      }

      @media only screen and (max-width: 767px) {
        margin-top: 0;
      }
    }

    .icon-container {margin: 0 auto;}

  }
}