.footer {
  background-color: #121212;

  .container {
    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;

      .copyrights {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
      
      .social-medias {
        display: flex;
        align-items: center;
        gap: 10px;

      }
    }
  }
}