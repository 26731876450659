.form-group {
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .text-area {
    width: 100%;
    height: 190px;
    border: 1px solid #3D3E40;
    font-size: 14px;
    padding: 15px 15px
  }
}