.header {
  z-index: 1000;
  position: relative;
  z-index: 10000;

  .top-bar {
    background: linear-gradient(90deg, #001750 34.23%, #4798C8 100%);

    .contact-info {
      height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      @media only screen and (max-width: 767px) {
        gap: 22px;
      }
      
      a {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        @media only screen and (max-width: 767px) {
          font-size: 12px;
          gap: 5px;

          img {display: none;}
        }

        &:hover {
          color: #F4CD30;
        }
      }
    }
  }  

  .navigation-bar {
    background-color: #001750;
    height: 88px;
    
    .logo {
      position: absolute;
      left: 20px;
      top: -49px;

      @media only screen and (max-width: 767px) {
        top: 5px;

        a img {
          height: 78px;
        }
      }
    }

    .navigation {
      .hamburger {
        display: none;

        @media only screen and (max-width: 992px) {
          display: block;
          position: absolute;
          right: 30px;
          top: 30px;
          cursor: pointer;

          img { width: 30px; }
        }
      }
      ul.menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 32px;

        .mobile-logo {
          display: none;
          padding: 20px;
          border-bottom: 1px solid #0a225e;
          margin-bottom: 32px;
          width: 100%;

          img {
            width: 80px;
          }

          @media only screen and (max-width: 992px) {
            display: block;
          }
        }

        @media only screen and (max-width: 992px) {
          position: fixed;
          width: 260px;
          flex-direction: column;
          left: -462px;
          top: 0;
          bottom: 0;
          background-color: #001750;
          z-index: 100;
          transition: 0.3s ease all;
        }

        li {
          list-style: none;
          position: relative;

          span, a {
            font-size: 17px;
            font-weight: 500;
            color: #ffffff;
            padding: 33px 0;
            display: block;
            cursor: pointer;

            &:hover {
              color: #F4CD30;
            }

            &.active {
              color: #F4CD30;
            }
          }

          &.has-submenu {
            padding-right: 12px;

            > span {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-4px);
                left: calc(100% + 7px);
                width: 12px;
                height: 12px;
                background-image: url(../../assets/images/arrow-down-white.svg);
                background-position: center;
                background-repeat: no-repeat;
                
              }
            }

            .submenu {
              position: absolute;
              z-index: 10;
              width: 287px;
              border-radius: 6px;
              background-color: #FFDC51;
              padding: 7px 0;
              margin-top: -1px;
              display: none;

              li {
                display: block;

                span {
                  border-bottom: 1px solid #D9B730;
                  display: flex;
                  gap: 8px;
                  padding: 10px 14px;
                  color: #001750;

                  &:hover {
                    background-color: #D9B730;
                  }
                }

                &:last-child a {
                  border-bottom: none;
                }
              }
            }

            &:hover {
              & > span:before {
                background-image: url(../../assets/images/arrow-down-yellow.svg);
              }

              .submenu {display: block;}
            }
          }

          &.button {
            a {
              width: 151px;
              height: 52px;
              border-radius: 39px;
              padding: 15px 15px 17px;
              background-color: #54AFE6;
              display: flex;
              justify-content: center;
              font-weight: 600;
              color: #FFF;
              align-items: center;
            }

            &:hover a {
              background-color: #FFDC51;
              color: #001750;
            }
          }
        }

        &.mobile-menu {
          @media only screen and (max-width: 992px) {
            left: 0;
            gap: 0;
            justify-content: left;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
              width: 5px;
              border-radius: 10px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: #000; 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #3a6ab9; 
            }
            
            li {
              text-align: left;
              display: block;
              width: 100%;

              span, a {
                padding: 16px 20px;
              }

              &.button {
                padding-top: 36px;
                padding-left: 20px;
              }

              &.has-submenu {
                span {
                  &:before {
                    left: calc(100% - 16px);
                  }
                }

                &:hover {
                  .submenu {
                    position: static;
                    background: none;
                    width: 100%;
                    padding-left: 12px;
                    border-radius: 0;

                    li {
                      span {
                        color: #fff;
                        border-bottom: none;
                        font-size: 14px;
                        padding-left: 32px;

                        img { display: none;}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.sticky {
    .navigation-bar {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      transition: 0.3s ease all;
    
      .logo {
        top: 5px;

        a img {height: 78px; transition: 0.3s ease all;}
      }
    }
  }
}