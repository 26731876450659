

.hero-section {
  position: relative;

  .hero-slider {
    .slider-wrapper {
      max-height: 497px !important;
    }
  
    .carousel-slider {
      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 23, 80, 0.69);
      }
  
      button.control-arrow {
        z-index: 1000;
  
        &:before { display: none; }
        width: 88px;
        height: 88px;

        @media only screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
          background-size: cover !important;
        }
        
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
  
        &.control-prev {
          background: url(../../assets/images/left-arrow.svg);
        }
  
        &.control-next {
          background: url(../../assets/images/right-arrow.svg);
        }
      }
    }
  }

  .hero-title {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-content {
      max-width: 750px;
      text-align: center;

      h1 {
        color: #fff;
        font-size: 64px;
        line-height: 64px;
        font-weight: 100;
        margin-bottom: 16px;

        @media only screen and (max-width: 992px) {
          font-size: 54px;
          line-height: 54px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 30px;
          line-height: 34px;
        }
    
        span {
          font-weight: 700;
        }
      }
    
      a {
        color: #FFDE59;
        font-size: 32px;
        text-decoration: underline;
        
        @media only screen and (max-width: 992px) {
          font-size: 28px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 18px;
        }
      }
    
      p {
        font-size: 32px;
        color: #fff;

        @media only screen and (max-width: 992px) {
          font-size: 24px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
