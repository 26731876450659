.manufacturer-section {
  padding: 50px 0 200px; 

  .manufacturer-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;

    li {
      list-style: none;
      width: 200px;
      border: 1px solid #CCC;
      text-align: center;
      border-radius: 6px;
      background-color: #ffffff;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .manufacture-logo {
        display: block;
        margin-bottom: 10px;

        img {
          max-height: 30px;
          max-width: 80%;
        }
      }
 
      .label {
        color: #001750;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      &:hover {
        box-shadow: 0px 0px 20px rgba(0, 23, 80, 0.18);
      }
    }
  }
}
