.intro-block {
  padding: 100px 0 0;

  .content-wrapper {
    display: flex;
    gap: 24px;

    @media only screen and (max-width:868px) {
      flex-direction: column;
    } 

    .card {
      padding: 44px 24px;
      min-height: 440px;
      flex: 1;
      text-align: center;
      transition: 0.3s ease all;

      @media only screen and (max-width:868px) {
        min-height: unset;
      } 

      &:hover {
        box-shadow: 6px 8px 14px rgba(0, 23, 80, 0.25)
      }

      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 600;
      }

      p{
        color: #fff;
        font-size: 16px;
        line-height: 28px;

        @media only screen and (max-width:868px) {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
        } 
      }

      &.yellow { 
        background-color: #FFDE59; 

        h3, p {
          color: #001750;
        }
      }
      &.blue { background-color: #001750; }
      &.sky-blue { background-color: #4798C8; }
    }
  }
}