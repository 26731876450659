.product-components {
  padding: 0 0 100px; 
  
  @media only screen and (max-width:767px) {
    padding-bottom: 40px;
  }

  .components-list {
    width: 100%;
    max-width: 680px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 20px auto 0;
    
    @media only screen and (max-width:767px) {
      max-width: 80%;
    }

    li {
      width: calc(50% - 15px);
      list-style: none;
      font-weight: 500;
      display: flex;
      gap: 11px;

      @media only screen and (max-width:767px) {
        width: 100%;
      }
    }

    @media only screen and (max-width:767px) {
      flex-direction: column;
    }
  }
}