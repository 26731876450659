.file-upload {
  height: 300px;
  border: 1px dashed #B5B5B5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  position: relative;
  
  input {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
  }

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    color: #94A0A8;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #94A0A8;
  }
  span.upload-btn {
    width: 189px;
    height: 52px;
    background: #54AFE6;
    border-radius: 39px;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.uploaded-file {
  height: 46px;
  border: 1px solid #ccc;
  display: flex;
  background-color: #f7f7f7;
  gap: 10px;
  font-weight: 500;
  width: fit-content;
  align-items: center;
  padding: 0 20px 0 12px;
  margin-top: 20px;
  position: relative;

  span.close {
    width: 22px;
    height: 22px;
    background-color: #F44336;
    position: absolute;
    right: -11px;
    top: -11px;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}