.partners-section {
  padding: 150px 0;

  h2 {
    font-size: 48px;
    line-height: 21px;
    border-bottom: 5px solid #001750;
    margin-bottom: 90px;
    padding-bottom: 26px;
    color: #001750;

    @media only screen and (max-width: 992px) {
      font-size: 34px;
      margin-bottom: 40px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 30px;
    }

    &.center {
      text-align: center;
      border-bottom: none;
      padding-bottom: 0;

      &:after {
        content: '';
        display: block;
        width: 110px;
        height: 7px;
        background-color: #001750;
        margin: 26px auto 0;
      }
    }
  }

  .partner-logos-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;

    .partner-logo-wrapper {
      border: 1px solid #D7D7D7;
      height: 86px;
      width: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

      img {
        max-width: 70%;
        max-height: 70px;
      }

      // @media only screen and (max-width:1024px) {
      //   width: calc(20% - 10px);
      // }

      @media only screen and (max-width:575px) {
        width: 210px;
      }

      &:hover {
        box-shadow: 0px 0px 20px rgba(0, 23,80,0.18);
      }
    }
  }
}